<script setup lang="ts">
const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  borderBottom: {
    type: Boolean,
    default: true,
  },
})
const slots = defineSlots<{
  default: any
  custom_btm?: any
}>()
const noBorder = computed(() => {
  return props.borderBottom
})
</script>

<template>
  <div class="max-w-full px-8 pt-8 pb-0 mt-8 bg-white rounded-lg shadow-sm">
    <div v-if="props.title !== null" :class="noBorder ? 'border-b-2' : 'border-b-0'" class="">
      <div class="">
        <h4 v-if="props.title" class="mb-[27px] font-semibold">
          {{ props.title }}
        </h4>
      </div>
    </div>
    <div class="pb-3">
      <slot />
      <slot v-if="slots.custom_btm" name="custom_btm" />
    </div>
  </div>
</template>
